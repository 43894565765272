<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();
const english = computed(() => store.getters.getLanguage);
const frameworks = computed(() => store.getters.getFrameworks);

</script>

<template>
    <div class="tarjeta">
        <h4 class="mb-5">Frameworks</h4>
        <div class="flex items-center gap-2 mb-2" v-for="framework in frameworks">
            <div class="w-2/5 md:w-1/3">
                {{ framework.name }}
            </div>
            <div class="h-1 w-full bg-blue-200 dark:bg-neutral-600">
                <div class="h-1 bg-blue-800" :style="{ width:framework.percentage + '%' }"></div>
            </div>

        </div>
    </div>
</template>