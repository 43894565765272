// export const myGetter = (state) => {

// }

export const getLanguage = (state) => {
    return state.isEnglish;
}

export const getJobs = (state) => {
    return state.jobs;
}

export const getSchools = (state) => {
    return state.schools;
}

export const getProyects = (state) => {
    return state.proyects;
}

export const getSkills = (state) => {
    return state.skills;
}

export const getFrameworks = (state) => {
    return state.frameworks;
}