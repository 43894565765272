<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';

import About from '@/components/About.vue';
import Information from '@/components/Information.vue';
import Perfil from '@/components/Perfil.vue';
import Skills from '@/components/Skills.vue';
import Frameworks from '@/components/Frameworks.vue';

const store = useStore();
const english = computed(() => store.getters.getLanguage);
const language = () => store.commit('changeLanguage');

</script>

<template>

<main class="contenedor">
    <div class="flex justify-end items-center mb-5">
        <img src="./assets/us.svg" alt="eua" class="h-5 mr-2 cursor-pointer" @click.prevent="language" v-if="english"> 
        <img src="./assets/mx.svg" alt="eua" class="h-5 mr-2 cursor-pointer" @click.prevent="language" v-else>  
    </div>
        <div class="grid gap-5 lg:grid-cols-3">
            <!-- Start Left Side -->
            <div class="space-y-5">

                <Perfil/>
                <Information/>
                <Skills/>
                <Frameworks/>

            </div>
            <!-- End Left Side -->

            <!-- Start Right Side -->
            <div class="space-y-5 lg:col-span-2">

               <About/>
               <router-view/>

            </div>
            <!-- End Right Side -->
        </div>
        <!-- Clases ocultas -->
        <div class="bg-[#09458F] hidden"></div>
        <div class="bg-green-800 hidden"></div>
        <div class="bg-green-900 hidden"></div>
        <div class="bg-blue-500 hidden"></div>

    </main>
</template>

