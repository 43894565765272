<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();
const english = computed(() => store.getters.getLanguage);

</script>

<template>
    <div class="tarjeta">
        <h4 class="mb-5">{{ english ? 'About me' : 'Acerca de mi'}}</h4>
        <p class="text-gray-600 mb-5 body-two" 
        v-html="english ? 
        '<p>I am a software development engineer, responsible for designing, developing, and maintaining Web systems and applications. I work collaboratively with other engineers, product managers, and designers to create high-quality software products that meet the needs of users. I have experience in software development methodologies such as Agile or Scrum, excellent problem-solving and analytical skills, also strong communication and collaboration skills</p><p>&nbsp;</p><p>More than 6 years of experience in software development.</p><p>&nbsp;</p><p>Responsibilities:&nbsp;</p><ul><li>Design and develop Web applications using languages such as PHP and JavaScript.&nbsp;</li><li>Write clean, efficient, and maintainable code that follows best practices and standards.&nbsp;</li><li>Debug and troubleshoot applications.&nbsp;</li><li>Collaborate with cross-functional teams to define product requirements and specifications.&nbsp;</li><li>Keep up to date with emerging trends and technologies in web development.&nbsp;</li><li>Advise other engineers and provide guidance if it is necessary.</li></ul>' : 
        '<p>Soy un ingeniero en desarrollo de software, responsable de diseñar, desarrollar y mantener sistemas y aplicaciones Web. Trabajo en colaboración con otros ingenieros, gerentes de productos y diseñadores para crear productos de software de alta calidad que satisfagan las necesidades de los usuarios. Cuento con experiencia en metodologías de desarrollo de software como Agile o Scrum, habilidades de comunicación y colaboración.&nbsp;</p><p>&nbsp;</p><p>Más de 6 años de experiencia en el desarrollo de software.</p><p>&nbsp;</p><p>Responsabilidades:</p><ul><li>Diseñar y desarrollar aplicaciones Web usando lenguajes como PHP y JavaScript.</li><li>Escribir código limpio, eficiente y fácil de mantener que siga las mejores prácticas y estándares.</li><li>Depurar y solucionar problemas en aplicaciones.</li><li>Colaborar con equipos multifuncionales para definir los requisitos y especificaciones del producto.</li><li>Mantenerme actualizado con las tendencias y tecnologías emergentes en desarrollo web.</li><li>Asesorar a otros ingenieros y brindar orientación de ser necesario.</li></ul><p>&nbsp;</p>'"></p>
        
        
        <div class="flex flex-col space-y-4 text-blue-500">
            <a href="#0" class="subtitle-one hover:text-blue-700 w-fit">
                <i class="fa-solid fa-at mr-1"></i>
                <span>ing.miguelcovarrubias@gmail.com</span>
            </a>

            <ul class="flex space-x-5 list-none">
                <li>
                    <a href="https://github.com/MiguelCova" class="social-link-hover" target="_blank">
                        <i class="fa-brands fa-github text-3xl hover:text-blue-700"></i>
                    </a>
                </li>
                
            </ul>
        </div>

        <div class="border-t border-gray-200 my-5"></div>
        
        <ul class="flex space-x-8 font-medium list-none">
            <li>
                <router-link to="/" class="text-gray-800 hover:text-blue-700">{{ english ? 'Resume' : 'Curriculum'}}</router-link>
            </li>
            <li>
                <router-link to="/proyects" class="text-gray-800 hover:text-blue-700">{{ english ? 'Proyects' : 'Proyectos'}}</router-link>
            </li>
            
        </ul>
    </div>
    
</template>

<style scoped>
    .router-link-exact-active {
        @apply text-blue-700 border-b-4 border-blue-700 pb-7
    }
</style>