<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();
const english = computed(() => store.getters.getLanguage);

</script>

<template>
     <div class="tarjeta-imagen overflow-hidden">
        <div class="h-32 bg-header bg-center bg-cover "></div>
        <div class="pt-14 p-7 relative">
            <span class="bg-blue-300 text-white rounded-md py-1 px-2 absolute top-2 right-7">{{ english ? 'Busy' : 'Ocupado' }}</span>
            <a href="/personal_cv/"><img src="../assets/avatar.jpeg" alt="Avatar" class="border-2 border-gray-200 rounded-lg h-20 w-20 object-cover absolute -top-10"></a>
            <h4 class="text-gray-800 mb-1.5">Miguel Covarrubias</h4>
            <div class="subtitle-two text-gray-400 mb-7">{{ english ? 'Senior Developer' : 'Desarrollador Senior' }}</div>
            <div class="flex">
                <a href="/files/resume_miguelcovarrubias.pdf" class="btn bg-blue-500 flex justify-center w-full" download v-if="english">
                    <div class="subtitle-one">
                        Download CV 
                    </div>
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        class="h-6 w-6 ml-3" fill="none" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path></svg>
                    </a>
                <a href="/files/curriculum_miguelcovarrubias.pdf" class="btn bg-blue-500 flex justify-center w-full" download v-else>
                    <div class="subtitle-one">
                        Download CV 
                    </div>
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        class="h-6 w-6 ml-3" fill="none" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path></svg>
                    </a>
            </div>
        </div>
    </div>
    
</template>