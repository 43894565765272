<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();
const english = computed(() => store.getters.getLanguage);

</script>


<template>
    <div class="tarjeta">
        <h4 class="mb-5">{{ english ? 'Information' : 'Información' }}</h4>
        <div class="space-y-4">
            <div class="flex justify-between">
                <div class="text-gray-400">{{ english ? 'Location' : 'Ubicación' }}</div>
                <div class="font-medium text-right text-gray-600">México</div>
            </div>
            <div class="flex justify-between">
                <div class="text-gray-400">{{ english ? 'Experience' : 'Experiencia' }}</div>
                <div class="font-medium text-right text-gray-600">6+ {{ english ? 'years' : 'años' }}</div>
            </div>
            <div class="flex justify-between">
                <div class="text-gray-400">{{ english ? 'Availability' : 'Disponibilidad' }}</div>
                <div class="font-medium text-right text-gray-600">2 {{ english ? 'weaks' : 'semanas' }}</div>
            </div>
            <div class="flex justify-between">
                <div class="text-gray-400">{{ english ? 'Relocation' : 'Reubicación' }}</div>
                <div class="font-medium text-right text-gray-600">{{ english ? 'Yes' : 'Si' }}</div>
            </div>
        </div>
    </div>
</template>