import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        path: '/', 
        component: () => import(/* webpackChunkName: "ResumePage" */ '@/components/Resume')
    },
    {
        path: '/proyects', 
        component: () => import('@/components/Proyects')
    },
    {
        path: '/:pathMatch(.*)*', 
        component: () => import('@/components/Resume')
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;