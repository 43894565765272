export default () => ({
    isLoading: true,
    isEnglish: false,
    skills: [
        {
            name: 'HTML',
            percentage: 60,
        },
        {
            name: 'CSS',
            percentage: 70,
        },
        {
            name: 'PHP',
            percentage: 70,
        },
        {
            name: 'JavaScript',
            percentage: 50,
        },
        {
            name: 'MySql',
            percentage: 40,
        },
    ],
    frameworks: [
        {
            name: 'Laravel',
            percentage: 60,
        },
        {
            name: 'Vue',
            percentage: 70,
        },
        {
            name: 'InertiaJs',
            percentage: 50,
        },
        {
            name: 'TailwindCss',
            percentage: 80,
        },
    ],
    jobs: [
        {
            logo: 'L',
            logoClass: 'bg-[#09458F] text-3xl',
            schedule: 'Full-time',
            horario: 'Tiempo Completo',
            company: 'Legamy',
            position: 'Senior Developer',
            posicion: 'Desarrollador Senior',
            locale: 'Mexico',
            ubicacion: 'México',
            date: 'August 2022 - Present',
            fecha: 'Agosto 2022 - Actual',
            description: '<ul><li>Participate in code reviews and provide constructive feedback to other team members.</li><li>Staying up-to-date with the latest trends and technologies in web development.</li><li>Strong technical skills in database administration, including experience with SQL Server or MySQL.</li><li>Building and maintaining databases and integrating them with web applications.</li><li>Design and develop software systems and applications using programming languages such as PHP or JavaScript.</li><li>Manage and maintain database documentation and user manuals.</li><li>Conducting testing and debugging to ensure that applications function correctly and are free of errors.</li><li>Collaborate with other IT professionals to integrate databases with other systems and applications.</li><li>Mentor junior software engineers and provide guidance and support as needed.</li><li>Debug and troubleshoot issues in software systems and applications.</li><li>Write clean, efficient, and maintainable code that follows best practices and coding standards.</li><li>Design and implement database backup and recovery procedures to ensure that data is protected in the event of a disaster.</li><li>Conducting testing and debugging to ensure that applications function correctly and are free of errors.</li><li>Collaborating with designers, project managers, and other developers to ensure that applications are delivered on time and within budget.</li><li>Building and maintaining databases and integrating them with web applications.</li></ul>',
            descripcion: '<ul><li>Participe en revisiones de código y brinde comentarios constructivos a otros miembros del equipo.</li><li>Mantenerse actualizado con las últimas tendencias y tecnologías en desarrollo web.</li><li>Fuertes habilidades técnicas en la administración de bases de datos, incluida la experiencia con SQL Server o MySQL.</li><li>Construir y mantener bases de datos e integrarlas con aplicaciones web.</li><li>Diseñar y desarrollar sistemas y aplicaciones de software utilizando lenguajes de programación como PHP o JavaScript.</li><li>Administrar y mantener la documentación de la base de datos y los manuales de usuario.</li><li>Realización de pruebas y depuración para garantizar que las aplicaciones funcionen correctamente y no tengan errores.</li><li>Colabore con otros profesionales de TI para integrar bases de datos con otros sistemas y aplicaciones.</li><li>Asesorar a los ingenieros de software júnior y brindar orientación y apoyo según sea necesario.</li><li>Depurar y solucionar problemas en sistemas y aplicaciones de software.</li><li>Escriba código limpio, eficiente y fácil de mantener que siga las mejores prácticas y estándares de codificación.</li><li>Diseñe e implemente procedimientos de respaldo y recuperación de bases de datos para garantizar que los datos estén protegidos en caso de un desastre.</li><li>Realización de pruebas y depuración para garantizar que las aplicaciones funcionen correctamente y no tengan errores.</li><li>Colaborar con diseñadores, gerentes de proyectos y otros desarrolladores para garantizar que las aplicaciones se entreguen a tiempo y dentro del presupuesto.</li><li>Construir y mantener bases de datos e integrarlas con aplicaciones web.</li></ul>',
            address: '4851 Acueduto Avenue, Floor 8, Puerta de Hierro, 45116, Zapopan, Jalisco',
            direccion: 'Avenida Acueducto 4851, Piso 8, Colonia Puerta de Hierro, C.P. 45116, Zapopan, Jalisco',
        },
        {
            logo: 'HC',
            logoClass: 'bg-green-800 text-2xl',
            schedule: 'Full-time',
            horario: 'Tiempo Completo',
            company: 'Hospital Civil de Guadalajara',
            position: 'Junior Developer',
            posicion: 'Desarrollador Junior',
            locale: 'Mexico',
            ubicacion: 'México',
            date: 'January 2018 - August 2022',
            fecha: 'Enero 2018 - Agosto 2022',
            description: "<ul><li>Manage the day-to-day operations of the office, including scheduling appointments, ordering supplies, and coordinating meetings.</li><li>Participate in code reviews and provide constructive feedback to other team members.</li><li>Strong technical skills in database administration, including experience with SQL Server or MySQL.</li><li>Building and maintaining databases and integrating them with web applications.</li><li>Manage and maintain database documentation and user manuals.</li><li>Collaborate with other IT professionals to integrate databases with other systems and applications.</li><li>Familiarity with database security policies and procedures.</li><li>Maintain and update the organization's databases, ensuring that they are secure, efficient, and reliable.</li></ul>",
            descripcion: '<ul><li>Administre las operaciones diarias de la oficina, incluida la programación de citas, el pedido de suministros y la coordinación de reuniones.</li><li>Participe en revisiones de código y brinde comentarios constructivos a otros miembros del equipo.</li><li>Fuertes habilidades técnicas en la administración de bases de datos, incluida la experiencia con SQL Server o MySQL.</li><li>Construir y mantener bases de datos e integrarlas con aplicaciones web.</li><li>Administrar y mantener la documentación de la base de datos y los manuales de usuario.</li><li>Colabore con otros profesionales de TI para integrar bases de datos con otros sistemas y aplicaciones.</li><li>Familiaridad con las políticas y procedimientos de seguridad de la base de datos.</li><li>Mantener y actualizar las bases de datos de la organización, asegurando que sean seguras, eficientes y confiables.</li></ul><p>&nbsp;</p>',
            address: '750 Salvador de Quevedo Street 750, Independencia Oriente, 44340, Guadalajara, Jalisco',
            direccion: 'Calle Salvador de Quevedo y Zubiera 750, Colonia Independencia Oriente , C.P. 44340, Guadalajara, Jalisco',

        },
        {
            logo: 'W',
            logoClass: 'bg-gray-400 text-2xl',
            schedule: 'Part-time',
            horario: 'Medio Tiempo',
            company: 'Self-employed',
            position: 'Junior Developer',
            posicion: 'Desarrollador Junior',
            locale: 'Mexico',
            ubicacion: 'México',
            date: 'January 2016 - August 2022',
            fecha: 'Enero 2016 - Agosto 2022',
            description: '<ul><li>Manage and maintain database documentation and user manuals.</li><li>Debug and troubleshoot issues in software systems and applications.</li><li>Familiarity with front-end development using HTML, CSS, and JavaScript.</li><li>Building and maintaining databases and integrating them with web applications.</li><li>Design and develop software systems and applications using programming languages such as PHP or JavaScript.</li><li>Designing and developing web applications that meet business requirements and user needs.</li></ul>',
            descripcion: '<ul><li>Administrar y mantener la documentación de la base de datos y los manuales de usuario.</li><li>Depurar y solucionar problemas en sistemas y aplicaciones de software.</li><li>Familiaridad con el desarrollo front-end usando HTML, CSS y JavaScript.</li><li>Construir y mantener bases de datos e integrarlas con aplicaciones web.</li><li>Diseñar y desarrollar sistemas y aplicaciones de software utilizando lenguajes de programación como PHP o JavaScript.</li><li>Diseñar y desarrollar aplicaciones web que satisfagan los requerimientos del negocio y las necesidades de los usuarios.</li></ul>',

        }
    ],
    schools: [
        {
            grade: 'Software Developmetn Enginering',
            grado: 'Ingenieria en Desarrollo de Software',
            school: 'Universidad Abierta y a Distancia de México',
            date: '2015 - 2019',
            logoClass: 'bg-green-900',
            logo: 'Unadm',
            link: 'https://www.unadmexico.mx/ofertaeducativa/licenciaturas/division-de-ciencias-exactas-ingenieria-y-tecnologia/desarrollo-de-software',
            document: '12134564',

        },
        {
            grade: 'Dental Surgeon',
            grado: 'Cirujano Dentista',
            school: 'Universidad de Guadalajara',
            date: '2004 - 2009',
            logoClass: 'bg-gray-400',
            logo: 'U de G',
            link: 'http://pregrado.udg.mx/Centros/Tem%C3%A1ticos/CUCS/cirujano-dentista-cucs#:~:text=La%20Licenciatura%20de%20Cirujano%20Dentista%20del%20Centro%20Universitario,a%20esto%2C%20los%20alumnos%20pueden%20adquirir%20su%20t%C3%ADtulo.',
            document: '6923952',
        }
    ],
    proyects: [
        
        {
            name: 'Studius',
            url: 'https://studius.legamy.com',
            description: 'The Case-Based Learning Platform for Lawyers, accountants or corpate advisors is a software project that aims to provide lawyers with a unique and engaging learning experience by offering courses that are based on real-life case studies. The platform offers a variety of courses and training modules that cover a wide range of legal topics, including corporate law, intellectual property, and litigation. The Case-Based Learning Platform for Lawyers is a valuable tool for lawyers and law firms alike, providing a practical and engaging way to enhance knowledge and skills, develop critical thinking and problem-solving skills, and increase employee satisfaction and retention.',
            descripcion: 'La plataforma de aprendizaje basada en casos para abogados, contadores o asesores corporativos es un proyecto de software que tiene como objetivo brindar a los abogados una experiencia de aprendizaje única y atractiva al ofrecer cursos basados en estudios de casos de la vida real. La plataforma ofrece una variedad de cursos y módulos de capacitación que cubren una amplia gama de temas legales, incluido el derecho corporativo, la propiedad intelectual y los litigios. La Plataforma de aprendizaje basada en casos para abogados es una herramienta valiosa tanto para abogados como para bufetes de abogados, ya que proporciona una forma práctica y atractiva de mejorar el conocimiento y las habilidades, desarrollar el pensamiento crítico y las habilidades para resolver problemas, y aumentar la satisfacción y retención de los empleados.',
            logo: 'S',
            logoClass: 'bg-blue-500',
            date: '2022 - Present',
            fecha: '2022 - Actualmente',
            type: 'Educative',
            tipo: 'Educativo',
            level: 'Co-funder',
            nivel: 'Co-fundador',

        },
        {
            name: 'Legamy',
            url: 'https://legamy.com',
            description: 'The Legal Education Platform is a software project that aims to provide lawyers, accountants or corpate advisors  with an interactive and engaging learning experience. The platform offers a variety of modules that cover a wide range of legal topics, including contract law, intellectual property, and litigation.',
            descripcion: 'Es una plataforma de educación jurídica, que tiene como objetivo brindar a los abogados, contadores o asesores corporativos, una experiencia de aprendizaje interactiva y atractiva. La plataforma ofrece una variedad de cursos y módulos que cubren una amplia gama de temas legales, incluidos el derecho contractural, la propiedad intelectual y los litigios.',
            logo: 'L',
            logoClass: 'bg-[#09458F]',
            date: '2018 - Present',
            fecha: '2018 - Actualmente',
            type: 'Educative',
            tipo: 'Educativo',
            level: 'Co-funder',
            nivel: 'Co-fundador',
        },
    ],
})